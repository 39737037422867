// extracted by mini-css-extract-plugin
export var canvas = "index-module--canvas--2oXMZ";
export var canvasContainer = "index-module--canvasContainer--1cYGj";
export var colourButton = "index-module--colourButton--1jqVR";
export var colourButtons = "index-module--colourButtons--1vUzQ";
export var darkButton = "index-module--darkButton--2DJMF";
export var exportedValue = "index-module--exportedValue--27gf_";
export var overlayCanvas = "index-module--overlayCanvas--25gKu";
export var selectedColours = "index-module--selectedColours--1f4VE";
export var selectedPrimaryColour = "index-module--selectedPrimaryColour--2E4hf";
export var selectedSecondaryColour = "index-module--selectedSecondaryColour--3SS3L";
export var tempCanvas = "index-module--tempCanvas--1AQsI";